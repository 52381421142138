<template>
    <div v-if="dialog">
        <v-dialog :value="dialog"
                  persistent
                  transition="dialog-bottom-transition">

            <v-card>
                <v-toolbar>
                    <v-btn icon @click="$emit('onCloseDialog')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title> {{ !setting._id ? $t('title.create_lottoSetting') :$t('title.update_lottoSetting')  }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn v-if="loading"
                               disabled
                               text
                               @click="validate">
                            {{ !setting._id ? $t('button.create') : $t('button.update') }}
                        </v-btn>
                        <v-btn v-else
                               text
                               @click="validate">
                            {{ !setting._id ? $t('button.create') : $t('button.update') }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <Loading :visible="loading" />
                <v-card-text>
                    <v-container>
                        <v-form ref="form"
                                v-model="valid"
                                lazy-validation>
                                <v-row class="mt-5">
                                <v-text-field v-model="setting.percentage"
                                              :label="$t('input.percentage')"
                                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                              :rules="rules.percentage"
                                              type="number" />
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            darkmode: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            rules: {
                type: Object,
                default: function () {
                    return {
                        max_lose: [
                            v => !!v || 'MaxLoose is required',
                        ],
                        max_win: [
                            v => !!v || 'MaxWin is required',
                        ],
                        percentage: [(v) => {
                            if (
                            !isNaN(parseFloat(v)) &&
                            v >= 0 &&
                            v <= 100 &&
                            !!v
                            )
                            return true;
                            return "Percentage has to be smaller than 100"
                        }],
                    }
                }
            },
            isValidate: {
                type: Boolean,
                default: true
            },
            setting: { 
                type: Object, 
                default: () =>({
                        _id: null,
                        name: null,
                        is_enable: false,
                        is_start: false,
                        user_id: null,
                        max_lose: 0,
                        max_win: 0,
                        win_lose_type: "1",
                        percentage:0,
                        updatedAt: null
                    }
                )
            },
        },
        computed: {
            valid: {
                // getter
                get: function () {
                    return this.isValidate
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:isValidate', newValue)
                }
            }
        },
        watch: {
            
        },
        methods: {
            
            validate() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit')
                }
            },
        }
    }

</script>
